import { isBusinessesForSaleData, isChildenData, isCommercialData, isCommercialForLeaseData, isCommercialForSaleData, isEstimateData, isLandHomeData, isRentData, isRuralData, isSoldData } from '@utils/house'
import { pushDataLayer } from '../index'
import { PageViewLayer } from '@component/dataLayer'
import { cond, constant, get, stubTrue, toLower } from 'lodash-es'
import { joinStr } from '@utils'

// event = 'event'
// event_journey = event_label
// event_interaction = event_action
// event_type = event_category

export default function PageViewDataLayer({
  data
}) {

  const isEstimate = isEstimateData(data)

  if (isEstimate) {

    const value = {
      recentSold: !!get(data, 'soldInfo'),
      buildingType: get(data, 'category') || '',
      propertyId: get(data, 'propertyId') || '',
      suburb: get(data, 'suburb'),
      district: get(data, 'district'),
      region: get(data, 'region')
    }

    return (
      <PageViewLayer
        data={{
          'vertical': 'valuation',
          'vertical_type': 'valuation',
          'event': 'page_view',
          'page_type': 'detail',
          'recently_sold': '{recentSold}',
          'building_type': '{buildingType}',
          'property_id': '{propertyId}',
          'suburb_name': '{suburb}',
          'district_name': '{district}',
          'region_name': '{region}'
        }}
        value={value}
      ></PageViewLayer>
    )
  } else {

    const isRent = isRentData(data)
    const isSold = isSoldData(data)
    const isCommercial = isCommercialData(data)
    const isCommercialSale = isCommercialForSaleData(data)
    const isCommercialLease = isCommercialForLeaseData(data)
    const isBusinessesSale = isBusinessesForSaleData(data)
    const isRural = isRuralData(data)
    const isChilden = isChildenData(data)
    const isLandHome = isLandHomeData(data)

    const vertical = cond([
      [constant(isChilden), constant('new homes')],
      [constant(isRent), constant('rent')],
      [constant(isBusinessesSale), constant('business')],
      [constant(isCommercial), constant('commercial')],
      [constant(isSold), constant('sold')],
      [constant(isRural), constant('rural')],
      [stubTrue, constant('buy')],
    ])()

    const saleType = cond([
      [constant(isRent), constant('rent')],
      [constant(isCommercialLease), constant('lease')],
      [constant(isCommercialSale || isBusinessesSale), constant('sale')],
      [stubTrue, constant('buy')],
    ])()

    const verticalType = cond([
      [isChilden && !isLandHome, constant('new apartments & terrace housing')],
      [isChilden && isLandHome, constant('house & land')],
      [isRent, constant('rent')],
      [isCommercialSale, constant('commercial for sale')],
      [isCommercialLease, constant('commercial for lease')],
      [isBusinessesSale, constant('businesses for sale')],
      [isSold, constant('sold')],
      [isRural, constant('rural')],
      [stubTrue, constant('buy')],
    ])()

    const value = {
      vertical,
      verticalType,
      saleType,
      category: get(data, 'category') || '',
      listingId: get(data, 'houseId') || '',
      propertyId: get(data, 'propertyId') || '',
      suburb: get(data, 'suburb'),
      district: get(data, 'district'),
      region: get(data, 'region'),
      bedrooms: get(data, 'bedrooms') || '',
      bathrooms: get(data, 'bathrooms') || '',
      brandName: get(data, 'office.branch', ''),
      officeName: get(data, 'office.name', '')
    }
    return (
      <PageViewLayer
        data={{
          'vertical': '{vertical}',
          'vertical_type': '{verticalType}',
          'event': 'page_view',
          'page_type': 'listing',
          'sale_type': '{saleType}',
          'property_type': '{category}',
          'listing_id': '{listingId}',
          'property_id': '{propertyId}',
          'suburb_name': '{suburb}',
          'district_name': '{district}',
          'region_name': '{region}',
          'bedrooms': '{bedrooms}',
          'bathrooms': '{bathrooms}',
          'brand_name': '{brandName}',
          'office_name': '{officeName}'
        }}
        value={value}
      ></PageViewLayer>
    )
  }

}

export const propertyDataLayer = {
  propertyValueChange(tab) {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'select valuation type',
      'event_interaction': 'change type',
      'event_type': tab.event
    })
  },

  floorplanClick() {
    pushDataLayer({
      'event': 'ga4_event',
      'event_name': 'floor_plan'
    })
  },

  videoClick() {
    pushDataLayer({
      'event': 'ga4_event',
      'event_name': 'video_tour'
    })
  },

  threedClick() {
    pushDataLayer({
      'event': 'ga4_event',
      'event_name': '3d_tour'
    })
  },

  imageClick() {
    // pushDataLayer({
    //   'event': 'ga4_event',
    //   'event_name': 'open_gallery'
    // })
  },

  agentPhoneClick() {
    pushDataLayer({
      'event': 'ga4_event',
      'event_name': 'call_agent'
    })
  },

  similarPropertyClick(items) {
    pushDataLayer({
      'ecommerce': null
    })

    pushDataLayer({
      'event': 'view_item_list',
      'ecommerce': { items }
    })
  },

  shareClick() {
    pushDataLayer({
      'event': 'ga4_event',
      'event_name': 'share_listing'
    })
  },

  coverScroll() {
    pushDataLayer({
      'event': 'ga4_event',
      'event_name': 'gallery_scroll'
    })
  },

  calculator(loan, frequency, eventType = 'click calculate') {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Mortgage Calculator',
      'event_interaction': 'Calulate Payments',
      'event_type': eventType,
      'mortgage_details': {
        'property_price': loan.price,
        'deposit': loan.deposit,
        'loan_rate': loan.rate,
        'loan_term': loan.term,
        'frequency': toLower(frequency),
        'method': 'principal_plus_interest'
      }
    })
  },

  calculatorOpened() {
    pushDataLayer({
      'event': 'mortgage_calculator_section',
      'event_journey': 'section_expand',
      'event_interaction': 'Interactions',
      'event_type': 'Mortgage_Calculator'
    })
  },

  calculatorClosed() {
    pushDataLayer({
      'event': 'mortgage_calculator_section',
      'event_journey': 'section_close',
      'event_interaction': 'Interactions',
      'event_type': 'Mortgage_Calculator'
    })
  },

  calculatorConnectNow() {
    pushDataLayer({
      'event': 'mortgage_calculator_section',
      'event_journey': 'connect_now',
      'event_interaction': 'clicked',
      'event_type': 'mortgage_calculator'
    })
  },

  flooringOpened() {
    pushDataLayer({
      'event': 'flooring_section',
      'event_journey': 'section_expand',
      'event_interaction': 'Interactions',
      'event_type': 'Flooring'
    })
  },

  flooringClosed() {
    pushDataLayer({
      'event': 'flooring_section',
      'event_journey': 'section_close',
      'event_interaction': 'Interactions',
      'event_type': 'Flooring'
    })
  },

  flooringResult(type) {
    pushDataLayer({
      'event': 'flooring_section',
      'event_journey': joinStr(['section_expand', type], '/'),
      'event_interaction': 'Interactions',
      'event_type': 'Flooring'
    })
  },

  flooringConnectNow() {
    pushDataLayer({
      'event': 'flooring_section',
      'event_journey': 'section_expand',
      'event_interaction': 'Clicks',
      'event_type': 'Flooring'
    })
  },

  harmoneyOpened() {
    pushDataLayer({
      'event': 'harmoney_section',
      'event_journey': 'section_expand',
      'event_interaction': 'Interactions',
      'event_type': 'Harmoney'
    })
  },

  harmoneyClosed() {
    pushDataLayer({
      'event': 'harmoney_section',
      'event_journey': 'section_close',
      'event_interaction': 'Interactions',
      'event_type': 'Harmoney'
    })
  },

  harmoneyResult(type) {
    pushDataLayer({
      'event': 'harmoney_section',
      'event_journey': joinStr(['section_expand', type], '/'),
      'event_interaction': 'Interactions',
      'event_type': 'Harmoney'
    })
  },

  harmoneyConnectNow() {
    pushDataLayer({
      'event': 'harmoney_section',
      'event_journey': 'section_expand',
      'event_interaction': 'Clicks',
      'event_type': 'Harmoney'
    })
  },

  amiOpened() {
    pushDataLayer({
      'event': 'AMI_insurance_section',
      'event_journey': 'section_expand',
      'event_interaction': 'Interaction',
      'event_type': 'AMI_insurance'
    })
  },

  amiClosed() {
    pushDataLayer({
      'event': 'AMI_insurance_section',
      'event_journey': 'section_close',
      'event_interaction': 'Interaction',
      'event_type': 'AMI_insurance'
    })
  },

  amiHomeHub() {
    pushDataLayer({
      'event': 'AMI_insurance_section',
      'event_journey': 'AMI_homeHub',
      'event_interaction': 'click',
      'event_type': 'AMI_insurance'
    })
  },

  amiLearnBenefits() {
    pushDataLayer({
      'event': 'AMI_insurance_section',
      'event_journey': 'AMI_learn_benefits',
      'event_interaction': 'click',
      'event_type': 'AMI_insurance'
    })
  },

  amiConnectNow() {
    pushDataLayer({
      'event': 'AMI_insurance_section',
      'event_journey': 'get_a_quote',
      'event_interaction': 'click',
      'event_type': 'AMI_insurance'
    })
  },

  solarOpened() {
    pushDataLayer({
      'event': 'solar_calculator_section',
      'event_journey': 'section_expand',
      'event_interaction': 'Interaction',
      'event_type': 'Solar_Calculator'
    })
  },

  solarClosed() {
    pushDataLayer({
      'event': 'solar_calculator_section',
      'event_journey': 'section_close',
      'event_interaction': 'Interaction',
      'event_type': 'Solar_Calculator'
    })
  },

  solarResult(panels, sysType) {
    pushDataLayer({
      'event': 'solar_calculator_section',
      'event_journey': [panels, sysType].join('_of_'),
      'event_interaction': 'Interaction',
      'event_type': 'Solar_Calculator'
    })
  },

  solarConnectNow() {
    pushDataLayer({
      'event': 'solar_calculator_section',
      'event_journey': 'get_a_quote',
      'event_interaction': 'click',
      'event_type': 'Solar_Calculator'
    })
  },
}

export const trackDataLayer = {

  show() {
    pushDataLayer({
      'event': 'harmoney_section',
      'event_journey': 'Property Valuation Track',
      'event_interaction': 'Modal',
      'event_type': 'Enter email'
    })
  },

  registered() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Property Valuation Track',
      'event_interaction': 'Modal',
      'event_type': 'Sign in to track this property'
    })
  },

  notRegistered() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Property Valuation Track',
      'event_interaction': 'Modal',
      'event_type': 'Create password'
    })
  },

  signin() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Property Valuation Track',
      'event_interaction': 'Modal',
      'event_type': 'Sign in to track this property'
    })
  },

  success() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Property Valuation Track',
      'event_interaction': 'Modal',
      'event_type': 'Track property success'
    })
  }
}

export const enquiryDataLayer = {
  click(spec) {
    if (spec) {
      pushDataLayer({
        'event': 'analytics_track',
        'event_journey': 'Contact Agent',
        'event_interaction': 'Listings page',
        'event_type': `Email ${spec.value}`
      })
      return
    }
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Contact Agent',
      'event_interaction': 'Listings page',
      'event_type': 'Email'
    })
    pushDataLayer({
      'event': 'ga4_event',
      'event_name': 'email_agent'
    })
  },

  show() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Contact Agent',
      'event_interaction': 'Modal',
      'event_type': 'Email modal'
    })
  },

  registered() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Contact agent',
      'event_interaction': 'Modal',
      'event_type': 'Sign in'
    })
  },

  notRegistered() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Contact agent',
      'event_interaction': 'Modal',
      'event_type': 'Create password'
    })
  },

  success() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Contact Agent',
      'event_interaction': 'Modal',
      'event_type': 'Email agent success'
    })
  },

  trackSuccess() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Contact agent',
      'event_interaction': 'Modal',
      'event_type': 'Track this property success'
    })
  },

  specs: {
    virtualviewing() {
      pushDataLayer({
        'event': 'analytics_track',
        'event_journey': 'Contact Agent',
        'event_interaction': 'Modal',
        'event_type': 'Request virtual viewing'
      })
    },

    online() {
      pushDataLayer({
        'event': 'analytics_track',
        'event_journey': 'Contact Agent',
        'event_interaction': 'Modal',
        'event_type': 'Request Q&A'
      })
    },

    privateviewing() {
      pushDataLayer({
        'event': 'analytics_track',
        'event_journey': 'Contact Agent',
        'event_interaction': 'Modal',
        'event_type': 'Request private viewing'
      })
    }
  }
}

export const favouriteDataLayer = {
  click(buttonId) {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Save Property',
      'event_interaction': 'Modal',
      'event_type': 'Add to list',
      'button_id': buttonId
    })
  },

  iconClick() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Save Property',
      'event_interaction': 'Search results page',
      'event_type': 'Save property icon'
    })
  },

  show() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Save Property',
      'event_interaction': 'Modal',
      'event_type': 'Enter email'
    })
  },

  registered() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Save Property',
      'event_interaction': 'Modal',
      'event_type': 'Sign in to save this property'
    })
  },

  notRegistered() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Save Property',
      'event_interaction': 'Modal',
      'event_type': 'Create password'
    })
  },

  signin() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Save Property',
      'event_interaction': 'Modal',
      'event_type': 'Sign in to save this property'
    })
  },

  newFolder() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Save Property',
      'event_interaction': 'Modal',
      'event_type': 'Create a new list'
    })
  },

  newFolderSuccess() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Save Property',
      'event_interaction': 'Modal',
      'event_type': 'Create list success'
    })
  },

  success() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Save Property',
      'event_interaction': 'Modal',
      'event_type': 'Save property success'
    })
  },

  shareSuccess() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Share this list',
      'event_interaction': 'Modal',
      'event_type': 'Share this list success'
    })
  }
}

export const openhomeDataLayer = {
  click() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Open home',
      'event_interaction': 'Listings page',
      'event_type': 'Add to open home planner'
    })
  },

  show(online = false) {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': online ? 'VOH' : 'Open home',
      'event_interaction': 'Modal',
      'event_type': 'Enter email'
    })
  },

  registered(online = false) {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': online ? 'VOH' : 'Open home',
      'event_interaction': 'Modal',
      'event_type': 'Sign in to add this open home'
    })
  },

  notRegistered(online = false) {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': online ? 'VOH' : 'Open home',
      'event_interaction': 'Modal',
      'event_type': 'Create password'
    })
  },

  success() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Open home',
      'event_interaction': 'Modal',
      'event_type': 'Open home successfully added'
    })
  }
}

export const auctionDataLayer = {
  click() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Auction',
      'event_interaction': 'Listings page',
      'event_type': 'Add to auction planner'
    })
  },

  show(online = false) {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': online ? 'VOH' : 'auction',
      'event_interaction': 'Modal',
      'event_type': 'Enter email'
    })
  },

  registered(online = false) {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': online ? 'VOH' : 'auction',
      'event_interaction': 'Modal',
      'event_type': 'Sign in to add this open home'
    })
  },

  notRegistered(online = false) {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': online ? 'VOH' : 'auction',
      'event_interaction': 'Modal',
      'event_type': 'Create password'
    })
  },

  success() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Auction',
      'event_interaction': 'Modal',
      'event_type': 'Auction successfully added'
    })
  }
}

export const calendarDataLayer = {
  addGoogle() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Open home',
      'event_interaction': 'Modal',
      'event_type': 'Add to Google calendar'
    })
  },

  addOutlookOnline() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Open home',
      'event_interaction': 'Modal',
      'event_type': 'Add to Outlook calendar'
    })
  },

  addOutlook() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Open home',
      'event_interaction': 'Modal',
      'event_type': 'Add to Outlook calendar'
    })
  },

  addYahoo() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Open home',
      'event_interaction': 'Modal',
      'event_type': 'Add to Yahoo calendar'
    })
  },

  addApple() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Open home',
      'event_interaction': 'Modal',
      'event_type': 'Add to Apple calendar'
    })
  }
}